import React, { Component } from "react";
import styled from "styled-components";
import { FlexSpacer } from "legacy-js/react/components/Gap";
import { UserMenu } from "legacy-js/editor/components/UserMenu";
import { Icon, MenuItem } from "@material-ui/core";
import { ShowDialog } from "legacy-js/react/components/Dialogs/BaseDialog";
import { ShareDialog } from "legacy-js/react/views/PresentationSettings/dialogs/ShareDialog";
import { ds } from "js/core/models/dataService";
import { PopupMenu } from "legacy-js/react/components/PopupMenu";
import AppController from "legacy-js/core/AppController";
import PresentationEditorController from "legacy-js/editor/PresentationEditor/PresentationEditorController";
import SlideActionsMenu from "legacy-js/editor/PresentationEditor/Components/SlideActionsMenu";
import { themeColors } from "legacy-js/react/sharedStyles";
import UndoRedo from "legacy-js/react/components/UndoRedo";
import { appVersion } from "legacy-js/config";
import { TeamResourceTypes } from "legacy-js/react/views/TeamResources/TeamResources";
import { PresentationTemplateController } from "legacy-js/core/dataServices/PresentationTemplateDataService";
import { sortTagsByPopularity } from "js/core/utilities/utilities";
import CreateTeamTemplateDialog from "legacy-js/react/views/TeamResources/dialogs/CreateTeamTemplateDialog";

const organizeIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" id="organize-icon" width="19" height="18" viewBox="0 0 19 18">
        <g id="organize-squares" fill="#eee" fillRule="evenodd">
            <rect id="first-box" width="4" height="4" x="1.616" y=".719" />
            <rect id="second-box" width="4" height="4" x="7.788" y=".719" />
            <rect id="third-box" width="4" height="4" x="1.616" y="7.216" />
            <rect id="fourth-box" width="4" height="4" x="1.616" y="13.719" />
            <rect width="4" height="4" x="7.788" y="13.719" />
            <rect width="4" height="4" x="7.788" y="7.216" />
            <rect width="4" height="4" x="13.959" y="13.719" />
            <rect width="4" height="4" x="13.959" y="7.216" />
            <rect width="4" height="4" x="13.959" y=".719" />
        </g>
    </svg>);

const MenuBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #222;
  width: 100%;
  height: 50px;
  padding: 0px;
  color: white;
  flex-shrink: 0;
  gap: 20px;

  .MuiIcon-root {
    color: white;
  }
`;

const NavMenuContainer = styled.div`
  width: 65px;
  height: 100%;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  .material-icons {
    color: ${themeColors.ui_blue};
  }
`;

const UserMenuContainer = styled.div`
  width: 60px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconMenuButton = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .material-icons {
    font-size: 20px;
    color: #eee;
  }

  &:hover {
    .material-icons {
      color: ${themeColors.ui_blue};
    }
  }
`;

const ShareButton = styled.div`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;

  span {
    margin-right: 10px;
  }

  &:hover {
    color: ${themeColors.ui_blue};

    .material-icons {
      color: ${themeColors.ui_blue};
    }
  }
`;

const PlayButton = styled.div`
  display: flex;
  align-items: center;

  .material-icons:hover {
    color: ${themeColors.ui_blue};
  }
`;

const PresentationName = styled.div`
  color: white;
  font-size: 15px;
  //min-width: 20px;
  cursor: pointer;
  //margin-left: 20px;
  //display: flex;
  //align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;


  &:hover {
    color: ${themeColors.ui_blue};
  }

  @media (max-width: 1000px) {
    max-width: 15%;
  }
`;

const MenuBarButton = styled.div`
  padding: 6px 12px;
  border-radius: 18px;
  height: 30px;
  background: ${themeColors.ui_blue};
  display: flex;
  align-items: center;
  justify-content: center;

  text-transform: uppercase;
  letter-spacing: 0.03em;
  white-space: nowrap;
  font-weight: 600;
  font-size: 12px;
`;

const SlideActionsButton = styled(MenuBarButton)`
  label {
    text-transform: uppercase;
    white-space: nowrap;
    font-weight: 600;
    font-size: 12px;
  }

  button {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }
`;

const UnskipSlideButton = styled(MenuBarButton)`
  background: #ffaa00;

  .material-icons {
    font-size: 16px;
    margin-right: 6px;
  }
`;

const SlideGridButton = styled(IconMenuButton)`
  label {
    font-weight: 600;
    color: white;
    font-size: 13px;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0.03em;
    margin-left: 10px;
  }

  &:hover {
    label {
      color: ${themeColors.ui_blue};
    }

    rect {
      fill: ${themeColors.ui_blue};
    }

    #first-box {
      transition: all 0.4s ease;
      transform: translateX(6.1px);
    }

    #second-box {
      transition: all 0.4s ease;
      transform: translateX(-6.1px);
    }

    #third-box {
      transition: all 0.4s 0.2s ease;
      transform: translateY(6.5px);
    }

    #fourth-box {
      transition: all 0.4s 0.2s ease;
      transform: translateY(-6.5px);
    }
  }
`;

const UpdateSlideButtonContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  .material-icons {
    background: #fa0;
    border-radius: 50%;
    padding: 1px;
    margin-right: 10px;
    color: #fff;
  }

  label {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
    color: #fa0;
    cursor: pointer;
  }
`;

const Template = styled.div`
  margin-left: -20px;
  background: #ff4500;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  margin-right: 10px;
  font-weight: 600;
  text-transform: uppercase;
`;

const LockedSlide = styled.div`
  background: #ff4500;
  color: #fff;
  min-width: 25px;
  height: 25px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  .material-icons {
    font-size: 14px;
  }
`;

const CollaborationLockedSlide = styled(LockedSlide)`
  padding: 0px 12px 0px 8px;
  text-transform: uppercase;
  font-weight: bold;

  .material-icons {
    margin-right: 6px;
  }
`;

class PresentationMenuBar extends Component {
    handleShare = () => {
        ShowDialog(ShareDialog, { presentation: ds.selection.presentation });
    }

    handlePlay = event => {
        const { presentation, currentSlide, slides } = this.props;

        if (event.shiftKey) {
            PresentationEditorController.playAnimation();
        } else if (event.metaKey) {
            AppController.playPresentation({ presentationId: presentation.id, slideIndex: 0 }, { isPreviewFromEditor: true });
        } else {
            AppController.playPresentation({ presentationId: presentation.id, slideIndex: slides.indexOf(currentSlide) }, { isPreviewFromEditor: true });
        }
    }

    render() {
        const { currentSlide, slides, currentCanvasController, currentSlideLockOwner, presentation, showSlideGrid } = this.props;

        const isSkippedSlide = presentation.isSlideSkipped(currentSlide);
        const templateName = currentCanvasController.getTemplateName();
        const isLibrarySlide = currentSlide.isLibrarySlide();
        const isTemplate = presentation.get("isTemplate");

        const canUpdateVersion = currentSlide.get("version") < appVersion;
        const isTemplateObsolete = currentCanvasController.isTemplateObsolete;

        const isClassicSlide = currentSlide.get("template_id") == "authoring";

        return (
            <MenuBarContainer id="menubar">
                <NavMenuContainer>
                    <PresentationMenu presentation={presentation} />
                </NavMenuContainer>

                {isTemplate && <Template>Template</Template>}

                <PresentationName id="presentation-name" onClick={() => PresentationEditorController.renamePresentation()}>
                    {presentation.get("name")}
                </PresentationName>

                <UndoRedo />

                <FlexSpacer />

                {!showSlideGrid &&
                    <>
                        {isLibrarySlide && <LockedSlide><Icon>lock</Icon></LockedSlide>}
                        {currentSlideLockOwner &&
                            <CollaborationLockedSlide><Icon>lock</Icon>{currentSlideLockOwner.displayName} is editing</CollaborationLockedSlide>}
                        <SlideGridButton id="show-slide-grid" onClick={() => PresentationEditorController.toggleSlideGrid()}>
                            {organizeIcon}
                            <label>
                                Slide {slides.indexOf(currentSlide) + 1} of {slides.length}
                            </label>
                        </SlideGridButton>

                        <SlideActionsButton style={{ background: isClassicSlide && themeColors.authoringThemeBaseColor }}>
                            <SlideActionsMenu disabled={currentSlideLockOwner} showArrow label={isLibrarySlide ? "Shared Slide" : templateName} />
                        </SlideActionsButton>

                        {canUpdateVersion && !isTemplateObsolete &&
                            <UpdateSlideButtonContainer
                                onClick={() => PresentationEditorController.updateSlideVersion(currentSlide, appVersion)}
                            >
                                <Icon>upgrade</Icon>
                                <label>Update Available</label>
                            </UpdateSlideButtonContainer>
                        }

                        {isSkippedSlide &&
                            <UnskipSlideButton
                                onClick={() => PresentationEditorController.setSkipSlide(currentSlide, false)}
                            >
                                <Icon>visibility</Icon>
                                Unskip
                            </UnskipSlideButton>
                        }
                    </>
                }
                {showSlideGrid &&
                    <SlideGridButton id="hide-slide-grid" onClick={() => PresentationEditorController.toggleSlideGrid()}>
                        {organizeIcon}
                        <label>{slides.length} Slides</label>
                    </SlideGridButton>
                }
                <FlexSpacer />
                <PlayButton>
                    <Icon onClick={this.handlePlay}>play_circle_filled</Icon>
                    <PopupMenu showArrow showHoverEffect={false}>
                        <MenuItem
                            onClick={() => AppController.playPresentation({ presentationId: presentation.id, slideIndex: PresentationEditorController.getCurrentSlideIndex() })}
                        >
                            Present from Current Slide
                        </MenuItem>
                        <MenuItem divider
                            onClick={() => AppController.playPresentation({ presentationId: presentation.id, slideIndex: 0 })}
                        >
                            Present from Beginning (Cmd-Click)
                        </MenuItem>
                        <MenuItem
                            onClick={() => PresentationEditorController.playAnimation()}
                        >
                            Preview Animation (Shift-Click)
                        </MenuItem>
                    </PopupMenu>
                </PlayButton>
                {
                    !isTemplate &&
                    <ShareButton onClick={this.handleShare}>
                        <Icon>share</Icon>
                        Share
                    </ShareButton>
                }
                <UserMenuContainer>
                    <UserMenu canSwitchWorkspace />
                </UserMenuContainer>
            </MenuBarContainer>
        );
    }
}

function PresentationMenu({ presentation }) {
    let handleReturn = () => {
        if (presentation.get("isTemplate")) {
            OnCreateNewPresentationTemplate(presentation);
        } else {
            AppController.showLibrary();
        }
    };

    if (presentation.get("isTemplate")) {
        return (
            <PopupMenu id="presentation-menu" icon="menu" childrenAreMenuItems showHoverEffect={false}>
                <MenuItem divider onClick={handleReturn}><Icon>list</Icon>Exit Template Editor</MenuItem>
            </PopupMenu>
        );
    } else {
        return (
            <PopupMenu id="presentation-menu" icon="menu" childrenAreMenuItems showHoverEffect={false}>
                <MenuItem divider onClick={handleReturn}><Icon>list</Icon>Return to Library</MenuItem>
                <MenuItem
                    onClick={() => AppController.showPresentationSettings("share", presentation)}><Icon>share</Icon>Sharing
                    Settings</MenuItem>
                <MenuItem
                    onClick={() => AppController.showPresentationSettings("collaborate", presentation)}><Icon>person_add_alt_1</Icon>Collaboration</MenuItem>
                <MenuItem
                    onClick={() => AppController.showPresentationSettings("player-settings", presentation)}><Icon>play_circle_outline</Icon>Player
                    Settings</MenuItem>
                <MenuItem divider
                    onClick={() => AppController.showPresentationSettings("analytics", presentation)}><Icon>show_chart</Icon>View
                    Analytics</MenuItem>
                <MenuItem divider
                    onClick={() => AppController.showPresentationSettings("version-history", presentation)}><Icon>history</Icon>Recover
                    Deleted Slides</MenuItem>
                <MenuItem
                    onClick={() => AppController.showPresentationSettings("export", presentation)}><Icon>cloud_download</Icon>Export
                    Presentation</MenuItem>
                <MenuItem
                    onClick={() => AppController.showPresentationSettings("export", presentation)}><Icon>print</Icon>Print
                    Presentation</MenuItem>
            </PopupMenu>
        );
    }
}

async function OnCreateNewPresentationTemplate(presentation) {
    let templates = await PresentationTemplateController.getTeamTemplates(null, true);
    let existingTags = sortTagsByPopularity(templates.map(item => item.tags));

    let isTemplateConfirmed = presentation.get("isTemplateConfirmed");

    // Undefined tags means this is a new/legacy template.
    //   So we should prompt for the user to add details
    if (!isTemplateConfirmed) {
        let name = presentation.get("name") || "";
        let description = presentation.get("description") || "";
        let tags = presentation.get("tags") || [];
        ds.selection.element = null;
        ShowDialog(CreateTeamTemplateDialog, {
            title: "Publish this template?",
            subTitle: "Publish this presentation now and make it available to your team to start from—or save it as a draft and publish it later.",
            name,
            description,
            tags,
            existingTags,
            callback: async ({
                isPublished,
                name,
                description,
                tags,
            }) => {
                await presentation.update({
                    isTemplateConfirmed: true,
                    isPublished,
                    name,
                    description,
                    tags,
                });

                AppController.showTeamResources({ pane: TeamResourceTypes.PRESENTATION_TEMPLATES });
            }
        });
    } else {
        AppController.showTeamResources({ pane: TeamResourceTypes.PRESENTATION_TEMPLATES });
    }
}

export default PresentationEditorController.withState(PresentationMenuBar);
