import React, { useState } from "react";
import styled from "styled-components";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { DialogTitle, DialogActions, Button, Radio } from "@material-ui/core";

import ProBadge from "legacy-js/react/components/ProBadge";
import { dialogTheme } from "legacy-js/react/materialThemeOverrides";
import { withFirebaseUser } from "legacy-js/react/views/Auth/FirebaseUserContext";
import { BeautifulDialog, DialogContent, ShowUpgradeDialog } from "legacy-js/react/components/Dialogs/BaseDialog";
import { UpgradePlanDialogType } from "legacy-js/react/views/MarketingDialogs/UpgradePlanDialog";
import AppController from "legacy-js/core/AppController";
import { ds } from "js/core/models/dataService";

const RadioContainer = styled.div`
    width: 100%;
    
    cursor: pointer;
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: flex-start;
`;

const RadioDescriptionContainer = styled.div.attrs(({ last }) => ({
    style: {
        marginBottom: last ? "0" : "10px"
    }
}))`
    width: 100%;
    margin-top: 10px;

    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
`;

const RadioDescriptionHeader = styled.div`
    margin-bottom: 10px;
    display: flex;

    font-weight: 600;
    font-size: 18px;
    line-height: 120%;
    letter-spacing: 0.5px;
    color: #222222;
`;

const RadioDescriptionText = styled.div`   
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #222222;
`;

function SaveSlideDialog({ onCancel, onSave, saveAsImageDefaultValue, isEduUser, workspaceId, user, firebaseUser }) {
    const orgId = AppController.orgId;

    const hasSeat = !!orgId && ds.teams.defaultTeamForOrg(orgId).checkIfUserHasSeat({
        id: user.id
    });

    const isBasicAccount = orgId ? !hasSeat : workspaceId === "personal" && user.personalPlan.planName === "basic" && !isEduUser;
    const [saveAsImage, setSaveAsImage] = useState(isBasicAccount || saveAsImageDefaultValue);

    const handleSaveAsEditableCick = () => {
        if (isBasicAccount) {
            ShowUpgradeDialog({
                type: UpgradePlanDialogType.UPGRADE_PLAN,
                analytics: {
                    cta: "SaveAsEditablePPTAddin"
                },
                workspaceId,
                closeDialog: successfulPurchase => {
                    if (!successfulPurchase) {
                        return;
                    }

                    AppController.initializeUser(firebaseUser)
                        .then(() => {
                            setSaveAsImage(false);
                        });
                }
            });
            return;
        }

        setSaveAsImage(false);
    };

    const handleSaveAsImageClick = () => {
        setSaveAsImage(true);
    };

    return (<MuiThemeProvider theme={dialogTheme}>
        <BeautifulDialog
            closeDialog={onCancel}
        >
            <DialogTitle>
                Would you like to save your slide?
            </DialogTitle>
            <DialogContent>
                <RadioContainer onClick={handleSaveAsEditableCick}>
                    <Radio
                        color="primary"
                        checked={isBasicAccount ? false : !saveAsImage}
                        disableRipple
                        disabled={isBasicAccount}
                    />
                    <RadioDescriptionContainer>
                        <RadioDescriptionHeader>
                            <div className="radio-button-label">Save as editable PowerPoint slide</div>
                            <ProBadge
                                show={isBasicAccount}
                                analytics={{ cta: "SaveAsEditablePPTAddin" }}
                                workspaceId={workspaceId}
                            />
                        </RadioDescriptionHeader>
                        <RadioDescriptionText>Edit right in PowerPoint. You may notice some slight differences after import. </RadioDescriptionText>
                    </RadioDescriptionContainer>
                </RadioContainer>
                <RadioContainer onClick={handleSaveAsImageClick}>
                    <Radio
                        color="primary"
                        checked={saveAsImage}
                        disableRipple
                    />
                    <RadioDescriptionContainer last>
                        <RadioDescriptionHeader>Save as image</RadioDescriptionHeader>
                        <RadioDescriptionText>Your slide will look exactly as it appears in Beautiful.ai. You can open it again in the Beautiful.ai editor to edit and reimport at any time.</RadioDescriptionText>
                    </RadioDescriptionContainer>
                </RadioContainer>
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    onClick={onCancel}
                >
                    cancel
                </Button>
                <Button
                    color="primary"
                    onClick={() => onSave(saveAsImage)}
                >
                    save to ppt
                </Button>
            </DialogActions>
        </BeautifulDialog>
    </MuiThemeProvider >);
}

export default AppController.withState(withFirebaseUser(SaveSlideDialog));
