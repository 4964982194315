import React from "react";
import styled from "styled-components";

import getLogger, { LogGroup } from "js/core/logger";
import { withFirebaseUser } from "legacy-js/react/views/Auth/FirebaseUserContext";
import AppController from "legacy-js/core/AppController";

import FetchingClickShield from "legacy-js/react/components/FetchingClickShield";
import MainViewEditor from "./Components/MainViewEditor";

const logger = getLogger(LogGroup.PPT_ADDIN);

const Container = styled.div`
  height: 100%;
  width: 100%;
  position: relative;

  background-color: #4b4e55;
`;

class PPTAddinEditor extends React.Component {
    constructor() {
        super();

        this.state = {
            fetching: true,
            renderEditor: false,
            isCreatingNewSlide: null,
            slideModel: null,
            slidePlaybackStage: null,
            themeModel: null,
            pptThemeModel: null,
            teamSlideId: null
        };
    }

    componentDidMount() {
        Office.context.ui.addHandlerAsync(
            Office.EventType.DialogParentMessageReceived,
            eventArgs => this.onTaskpaneMessageReceived(JSON.parse(eventArgs.message))
        );

        Office.context.ui.messageParent(JSON.stringify({ eventType: "mounted" }));
    }

    componentWillUnmount() {
        Office.context.ui.removeHandlerAsync(Office.EventType.DialogParentMessageReceived);
    }

    onTaskpaneMessageReceived = message => {
        const { firebaseUser } = this.props;
        if (message.eventType === "initialize") {
            (async () => {
                await AppController.initialize(firebaseUser, message.workspaceId);

                this.setState({
                    fetching: false,
                    renderEditor: true,
                    isCreatingNewSlide: message.isCreatingNewSlide,
                    slideModel: message.slideModel,
                    slidePlaybackStage: message.slidePlaybackStage,
                    themeModel: message.themeModel,
                    pptThemeModel: message.pptThemeModel,
                    teamSlideId: message.teamSlideId
                });
            })();
        }
    }

    sendTaskpaneMessage = message => {
        Office.context.ui.messageParent(JSON.stringify(message));
    }

    handleSaveSlide = ({ slideModel, themeModel, pptSlideData, workspaceId, templateId, templateTitle, isSavedAsImage }) => {
        this.setState({ fetching: true });

        this.sendTaskpaneMessage({
            eventType: "save-slide",
            slideModel,
            themeModel,
            pptSlideData,
            workspaceId,
            templateId,
            templateTitle,
            isSavedAsImage
        });
    }

    handleClose = () => {
        this.setState({ fetching: true });

        this.sendTaskpaneMessage({ eventType: "close" });
    }

    render() {
        const {
            firebaseUser
        } = this.props;
        const {
            fetching,
            renderEditor,
            slideModel,
            slidePlaybackStage,
            themeModel,
            pptThemeModel,
            isCreatingNewSlide,
            teamSlideId
        } = this.state;

        return (<Container>
            <FetchingClickShield visible={fetching} opaque />
            {!AppController.workspaceIsPastDue && renderEditor && <MainViewEditor
                isCreatingNewSlide={isCreatingNewSlide}
                teamSlideId={teamSlideId}
                slideModel={slideModel}
                slidePlaybackStage={slidePlaybackStage}
                themeModel={themeModel}
                pptThemeModel={pptThemeModel}
                firebaseUser={firebaseUser}
                onClose={this.handleClose}
                onSaveSlide={this.handleSaveSlide}
            />}
        </Container>);
    }
}

export default withFirebaseUser(PPTAddinEditor);
