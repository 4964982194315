import React from "react";
import styled from "styled-components";

const StyledLoadScreenIcon = styled.svg`
    transform: scale(1) !important;
    position: relative;
`;

function LoadScreenIcon({ style = {} }) {
    return (
        <StyledLoadScreenIcon
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="currentColor"
            style={{
                ...style
            }}
        >
            <g>
                <path d="M12.006 3C11.4609 3 11.019 3.44186 11.019 3.98691V6.94765C11.019 7.49271 11.4609 7.93456 12.006 7.93456C12.5511 7.93456 12.993 6.94765 12.993 6.94765V3.98691C12.993 3.44186 12.5511 3 12.006 3Z"/>
                <path opacity="0.9" d="M6.06941 5.45193C5.80832 5.45328 5.55858 5.55885 5.37571 5.7452C4.99032 6.13362 4.99032 6.76014 5.37571 7.14857L7.47288 9.22952C7.8596 9.59037 8.46271 9.57995 8.83672 9.20596C9.21074 8.83197 9.22116 8.2289 8.86028 7.84222L6.77918 5.7452C6.59021 5.55815 6.33531 5.45283 6.06941 5.45193Z"/>
                <path opacity="0.3" d="M17.9413 5.45193C17.6754 5.45283 17.4205 5.55815 17.2315 5.7452L15.1504 7.84222C14.7896 8.2289 14.8 8.83197 15.174 9.20596C15.548 9.57995 16.1511 9.59037 16.5378 9.22952L18.635 7.14857C19.0211 6.76044 19.0211 6.13332 18.635 5.7452C18.4521 5.55885 18.2024 5.45328 17.9413 5.45193Z"/>
                <path opacity="0.8" d="M3.61198 11.3881C3.06689 11.3881 2.625 11.8299 2.625 12.375C2.625 12.92 3.06689 13.3619 3.61198 13.3619H6.57293C7.11803 13.3619 7.55992 12.92 7.55992 12.375C7.55992 11.8299 7.11803 11.3881 6.57293 11.3881H3.61198Z"/>
                <path opacity="0.4" d="M17.427 11.3881C16.882 11.3881 16.4401 11.8299 16.4401 12.375C16.4401 12.92 16.882 13.3619 17.427 13.3619H20.388C20.9331 13.3619 21.375 12.92 21.375 12.375C21.375 11.8299 20.9331 11.3881 20.388 11.3881H17.427Z"/>
                <path opacity="0.7" d="M7.46623 15.5205C7.10197 15.8781 5.36906 17.6015 5.36906 17.6015C5.11835 17.8522 5.02044 18.2175 5.11221 18.56C5.20397 18.9024 5.47147 19.1699 5.81394 19.2617C6.15641 19.3534 6.52183 19.2555 6.77253 19.0048L8.85363 16.9078C9.21964 16.5178 9.21964 15.9105 8.85363 15.5205C8.45978 15.1728 7.8688 15.1723 7.47427 15.5192L7.46623 15.5205Z"/>
                <path opacity="0.5" d="M15.1451 15.5205C14.7791 15.9105 14.7791 16.5178 15.1451 16.9078L17.2262 19.0048C17.4769 19.2555 17.8423 19.3534 18.1848 19.2617C18.5273 19.1699 18.7948 18.9024 18.8865 18.56C18.9783 18.2175 18.8804 17.8521 18.6297 17.6014L16.5325 15.5205C16.1371 15.1769 15.5492 15.1763 15.1531 15.5192L15.1451 15.5205Z"/>
                <path opacity="0.6" d="M11.9993 16.8154C11.4542 16.8154 11.0123 17.2573 11.0123 17.8023V20.7631C11.0123 21.3081 11.4542 21.75 11.9993 21.75C12.5444 21.75 12.9863 21.3081 12.9863 20.7631V17.8023C12.987 17.2594 12.549 16.8178 12.006 16.8141L11.9993 16.8154Z"/>
            </g>
        </StyledLoadScreenIcon>
    );
}

export default LoadScreenIcon;
